import React, { useState } from "react";
import CmsBlock from "app/layout/cmsBlock";
import { MDBRow, MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";
import { useSelector } from "react-redux";

const NorwellBlocks = () => {
  const [readMore, setReadMore] = useState(false);
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );

  return (
    <>
      <div className="norwell-blocks-wrapper">
        <CmsBlock id="norwell_title_about_us" dangHtml="true" />
        <MDBRow className="first-block-about-us">
          <MDBCol lg="9">
            <MDBRow>
              <MDBCol lg="6">
                {/* <CmsBlock
                  id="about_us_first_block_first_column"
                  dangHtml={true}
                /> */}
                <div id="cmsblock-about_us_first_block_first_column" class="cmsblock undefined">
                  <p>For over 70 years, Norwell has created quality lighting solutions for the residential market. We design and engineer our products at our facilities in East Taunton, Massachusetts.</p>
                  <p>&nbsp;</p>
                  <p>As a means to simplify sourcing and fulfillment, we have created this catalog, which details the product offering of all three divisions. On this side of the book, you will discover Norwell.</p>
                  <p>&nbsp;</p>
                </div>
              </MDBCol>
              <MDBCol lg="6" className={"norwell-text " + readMore}>
                {/* <CmsBlock
                  id="about_us_first_block_second_column"
                  dangHtml={true}
                /> */}
                <div id="cmsblock-about_us_first_block_second_column" class="cmsblock undefined">
                  <p style={{textAlign:"left"}}>Developed for the residential customer, Norwell fixtures are currently available and ready to ship. Each comes in a variety of finishes, and some offer a choice of diffusers, to deliver a uniquely individual look utilizing our standard offerings.</p>
                  <p style={{textAlign:"left"}} className="mt-5">Since we are one company with many capabilities, under one roof, you can be certain all products will be made with the same hands-on expertise and attention to quality.</p>
                </div>
                <p className="read-more" onClick={() => setReadMore(!readMore)}>
                  Read {readMore ? "less" : "more"}
                </p>
              </MDBCol>

              {site.siteCode.includes("norwell") ? (
                <></>
              ) : (
                <>
                  <div className="about-us-visit-website">
                    <Icon icon="right_arrow_white" />
                    <CmsBlock
                      dangHtml={true}
                      id="about_us_visit_norwell_website"
                    />
                  </div>
                </>
              )}
            </MDBRow>
          </MDBCol>
          <MDBCol lg="3">
            <CmsBlock id="about_us_first_block_third_column" dangHtml={true} />
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default NorwellBlocks;
