import React, { memo } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import SeoHelmet from "app/layout/seoHelmet";
import CmsBlock from "app/layout/cmsBlock";
import { isEqual } from "lodash";
import Icon from "app/assets/icon/icon";
import ContactForm from "./contactForm";
import { useMediaQuery } from "react-responsive";

const Contact = memo(
  () => {
    const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
    return (
      <>
        <SeoHelmet title={"Contact Us"} url={"contact-us"} />
        <MDBContainer fluid className={"contact-us-container"}>
          {!isMobileOrTablet && (
            <MDBRow>
              <Breadcrumbs />
            </MDBRow>
          )}
          <MDBRow className="contact-us-title">
            <h3 className="wow fadeInLeft">Contact Us</h3>
          </MDBRow>
          <MDBRow className="contact-us-info-container">
            <MDBCol lg="4" className="contact-us-text">
              <MDBRow>
                <MDBCol>
                  <Icon icon="dark_logo_norwell" />
                  <CmsBlock id="norwell_contact_information" dangHtml={true} />
                </MDBCol>
              </MDBRow>
              {/* <MDBRow>
                <MDBCol>
                  <Icon icon="dark_logo_ilex" />
                  <CmsBlock id="ilex_contact_information" dangHtml={true} />
                </MDBCol>
              </MDBRow> */}
            </MDBCol>
            {!isMobileOrTablet && (
              <MDBCol lg="8" className="contact-us-image">
                <CmsBlock id="contact_us_image" dangHtml={true} />
              </MDBCol>
            )}
          </MDBRow>
          <MDBRow className="contact-us-form-container">
            <MDBCol className="contact-us-form-column">
              <ContactForm />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Contact;
