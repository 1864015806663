import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import SeoHelmet from "app/layout/seoHelmet";

const SiteMap = (props) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  let categories =
    typeof props.data === "undefined"
      ? " "
      : props.data.categoryList[0].children;
  return (
    <>
      <SeoHelmet title="Site Map" url={`/site-map`} />
      <MDBContainer className="site-map">
        {!isMobileOrTablet && (
          <MDBRow fluid className="breadcrumbs-container">
            <Breadcrumbs />
          </MDBRow>
        )}
        <h1>Site Map</h1>
        <hr />
        <br />

        <h2> Categories </h2>

        {typeof props.data === "undefined"
          ? " "
          : categories.map((item, index) => {
              return (
                <>
                  <MDBRow className="categories-wrapper-site-map">
                    <MDBCol>
                      <Link to={`${item.name}`}>{item.name}</Link>
                    </MDBCol>
                  </MDBRow>
                </>
              );
            })}
        <h2>Pages</h2>
        <MDBRow className="links-wrapper-site-map">
          <MDBCol md="4" className="site-map-cols">
            <Link to={"/about-us"}>About Us</Link>
            <br />
            {props.site.siteCode === "ilex_default" && (
              <>
                <Link to={"/custom"}>Custom</Link> <br />
              </>
            )}
            <Link to={"/catalog"}>Catalog</Link> <br />
            <Link to={"/gallery"}>Gallery</Link> <br />
          </MDBCol>
          <MDBCol md="4" className="site-map-cols">
            {/* {props.site.siteCode === "ilex_default" ? (
              <Link to={"/find-a-rep"}>Find A Rep</Link>
            ) : (
              <Link to={"/where-to-buy"}>Where To Buy</Link>
            )}
            <br /> */}
            <Link to={"/contact-us"}>Contact Us</Link> <br />
            {props.site.siteCode === "ilex_default" && (
              <>
                <Link to={"/press"}>Press</Link>
                <br />
              </>
            )}
            <Link to={"/privacy-policy"}>Privacy Policy</Link>
            <br />
          </MDBCol>
          <MDBCol md="4" className="site-map-cols">
            <Link to={"/terms-and-conditions"}>Terms and Conditions</Link>
            <br />
            <Link to={"/return-policy"}>Return Policy</Link>
            <br />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default SiteMap;
