import React from "react";
import { MDBView } from "mdbreact";
import { Link } from "react-router-dom";

const CatalogItem = (props) => {
  let { catalog } = props;
  const path = `/catalog-details/id_${catalog.catalogs_id}`;

  return (
		<>
			{catalog &&
				!catalog.title.toLowerCase().includes("ilex") && (
					<Link to={path}>
						{/* <a href={catalog.pdf && catalog.pdf} target="__blank"> */}
						<div>
							<MDBView
								waves
								className="catalog-item-image-and-text-wrapper">
								<img src={catalog.image} alt="catalog-item" />
								<div className="catalog-name-container">
									<p>{catalog.title}</p>
								</div>
							</MDBView>
						</div>
						{/* </a> */}
					</Link>
				)}
		</>
  )
};

export default CatalogItem;
