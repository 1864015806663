let color = "#e88602";
export default {
	magento_url: "https://magento.ilexlight.com/",
	graphql_url: "graphql",
	media_url: "/pub/media",
	catalog_url: "/catalog/product",
	category_url: "/catalog/category",
	app_url: "https://magento.ilexlight.com/",
	name: "Ilex",
	name_homepage: "ilex",
	root_category_id: 2,
	norwell_category_id: 3,
	ilex_category_id: 2,
	product_category_id: 2,
	sessionMessageTime: 4000,
	colors: {
		primary: color,
	},
	loader: {
		color: "#B9571B",
		height: 100,
		width: 100,
		type: "MutatingDots",
	},
	mediaGallery: {
		nav: "thumbs",
		desktop: {
			width: 800,
			height: 600,
		},
		mobile: {
			width: "auto",
			height: "auto",
		},
	},
	pricing: {
		defaultCurrency: "USD",
	},
}

export const storage = {
  local_storage_key: "norwell/ilex",
  throttle_local_storage_time: 200,
};
