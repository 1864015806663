import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import Breadcrumbs from "app/layout/breadcrumbs";
import ReturnPolicyForm from "./returnPolicyForm";
import { useMediaQuery } from "react-responsive";
import SeoHelmet from "app/layout/seoHelmet";

const ReturnPolicy = (props) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  const [readMore, setReadMore] = useState(false);
  return (
		<>
			<SeoHelmet title="Return Policy" url={`/return-policy`} />
			<MDBContainer className="return-policy-container">
				<MDBCol>
					<MDBRow className="return-policy-banner-image">
						<CmsBlock id="return_policy_image" dangHtml={true} />
					</MDBRow>
				</MDBCol>
				<MDBRow className="return-policy-content">
					{!isMobileOrTablet && <Breadcrumbs />}
					<MDBRow className="return-policy-title wow slideInUp">
						<MDBCol>
							<h3>Return Policy</h3>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol
							lg="8"
							className={"return-policy-text " + readMore}>
							<CmsBlock
								id="return_policy_main-norwell"
								dangHtml={true}
							/>
							{isMobileOrTablet && (
								<p
									className="read-more"
									onClick={() => setReadMore(!readMore)}>
									Read {readMore ? "less" : "more"}
								</p>
							)}
						</MDBCol>
						<ReturnPolicyForm />
					</MDBRow>
				</MDBRow>
			</MDBContainer>
		</>
  )
};

export default ReturnPolicy;
