import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import SocialMedia from "./socialMedia";

const FooterBottom = (props) => {
  const storeConfigCopyright = props.data.storeConfig.copyright
  const updatedCopyright = storeConfigCopyright.includes("2020")
		? storeConfigCopyright.replace("2020", new Date().getFullYear())
		: storeConfigCopyright
  return (
		<MDBRow lg="12" className={"footer_bottom"}>
			<MDBCol lg="5" className="social-media-container">
				<SocialMedia />
			</MDBCol>
			<MDBCol lg="2" className="copyright-container">
				<p>{updatedCopyright}</p>
			</MDBCol>
			<MDBCol lg="5" className={"links-container " + props.site.siteCode}>
				<CmsBlock id="footer_block_4" dangHtml={true} />
			</MDBCol>
		</MDBRow>
  )
};

export default FooterBottom;
