import React, { useState, useEffect, useRef } from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
// import SiteSwitcher from "app/layout/siteSwitcher";
import { Link } from "react-router-dom";
import AccountDropdown from "app/layout/accountDropdown";
import Menu from "app/layout/header/menu";
import Icon from "app/assets/icon/icon";
import Logo from "app/layout/logo";
import InvQuo from "app/layout/InvQuo";
import MobileHeader from "./mobile";
import Search from "../search/search";
import { useCart } from "app/state/hooks/cartHooks/useCart";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { useCallback } from "react";
import { useSelector } from "react-redux";

// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout definition for the website header
// this file should only be used to bootstrap new header components and transfer props, it should not contain logic
// You can also include an scss file that would contain CSS for the header container
// Keep in mind that this is loaded on every page of the website, so try to keep it and it's children light
// --------------------------------------------------------------------------------------------------------------------------------------------------------
/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, displaySearch, setDisplaySearch) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      event.target.tagName !== "svg" &&
      event.target.parentElement?.id !== "search-text" &&
      event.target.tagName !== "path"
    ) {
      if (displaySearch) {
        setDisplaySearch(!displaySearch);
      }
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

const Header = ({ props, data, mobile }) => {
  const [, , , getCartInformation] = useCart();
  const [getLoggedIn] = useCustomer();
  const [width, setWidth] = useState(window.innerWidth);
  const [toggleLoginRegisterModal, setToggleLoginRegisterModal] =
    useState(false);
  let token = getLoggedIn();
  let searchRef = useRef(null);
  let [searchVal, setSearchVal] = useState("");
  let [displaySearch, setDisplaySearch] = useState(false);

  if (data !== undefined) {
    let sortedCategories = data.categoryList[0].children.sort((a, b) => {
      return a.position - b.position;
    });
    data.categoryList[0].children = sortedCategories;
  }

  const onResizeSetWidth = () => {
    setWidth(window.innerWidth);
  };

  useOutsideAlerter(searchRef, displaySearch, setDisplaySearch);

  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  });
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );

  const callbackGetCartInformation = useCallback(() => {
    if (getLoggedIn()) {
      getCartInformation();
    }
  }, [token]);
  useEffect(() => {
    callbackGetCartInformation();
  }, [callbackGetCartInformation]);

  window.onscroll = () => {
    if (width > 1024) {
      const headerTop = window.document.getElementById("header-top-id");
      if (window.pageYOffset > 0) {
        if (headerTop !== null && headerTop?.style !== null) {
          headerTop.style.maxHeight = "0";
          headerTop.style.overflow = "hidden";
        }
      } else {
        if (headerTop !== null && headerTop?.style !== null) {
          headerTop.style.maxHeight = "5.7rem";
          headerTop.style.overflow = "visible";
        }
      }
    }
  };

  return (
    <MDBContainer
      fluid
      className={
        displaySearch
          ? "header-main-container search-active"
          : "header-main-container"
      }
      id="header-main-container-id"
    >
      {(!mobile && width > 1024 && (
        <>
          <MDBRow className="header-top-container" id="header-top-id">
            <MDBCol className="header-top-float-left col-lg-4">
              {/* <SiteSwitcher switcherLocation="header" /> */}
              <div className="elk-topbar-height"></div>
            </MDBCol>
            <MDBRow className="header-top-float-right col-lg-8">
              {/* <Link
                className="header-top-float-right-wtb_far-wrapper"
                to={
                  site.siteCode.includes("norwell")
                    ? "/where-to-buy"
                    : "/find-a-rep"
                }
              >
                {site.siteCode.includes("norwell")
                  ? "Where to Buy"
                  : "Find a Rep"}
              </Link> */}
              <InvQuo />
              {token ? (
                <Link
                  to="/favorites"
                  className="header-top-float-right-favorites-wrapper"
                >
                  <Icon icon="favorites" />
                </Link>
              ) : (
                <a
                  className="header-top-float-right-favorites-wrapper"
                  onClick={() => {
                    setToggleLoginRegisterModal(!toggleLoginRegisterModal);
                  }}
                >
                  <Icon icon="favorites" />
                </a>
              )}
              <AccountDropdown
                toggleLoginRegisterModal={toggleLoginRegisterModal}
                setToggleLoginRegisterModal={setToggleLoginRegisterModal}
                mobile={false}
              />
            </MDBRow>
          </MDBRow>
          <MDBRow className={"header-bottom-container " + displaySearch}>
            <MDBCol className="col-lg-2 header-logo">
              <Logo />
            </MDBCol>
            <MDBCol className={displaySearch ? "col-lg-12" : "col-lg-10"}>
              {displaySearch ? (
                <MDBCol>
                  <Search
                    open={displaySearch}
                    setOpen={setDisplaySearch}
                    searchVal={searchVal}
                    setSearchVal={setSearchVal}
                  />
                </MDBCol>
              ) : (
                <MDBRow>
                  <MDBCol className="header-bottom-float-right-container">
                    {typeof data !== "undefined" ? (
                      <Menu
                        menuItems={
                          typeof data !== "undefined" &&
                          typeof data.categoryList !== "undefined"
                            ? data.categoryList[0]
                            : {}
                        }
                        width={width}
                      />
                    ) : (
                      <></>
                    )}
                    <Icon
                      className="search-icon"
                      icon="search"
                      onClick={() => {
                        setDisplaySearch(!displaySearch);
                      }}
                    />
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCol>
          </MDBRow>
        </>
      )) || (
        <MobileHeader
          displaySearch={displaySearch}
          setDisplaySearch={setDisplaySearch}
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          menuItems={
            typeof data !== "undefined" &&
            typeof data.categoryList !== "undefined"
              ? data.categoryList[0]
              : {}
          }
        />
      )}
    </MDBContainer>
  );
};

export default Header;
